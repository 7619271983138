<template>
  <div role="main" class="main">
    <section class="common-page minh-100 guestbook-registration">
      <div class="container">
        <div class="loaderWrapper" v-if="showLoader">
          <double-bounce ></double-bounce>
        </div>
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="border-l text-left"></div>
          </div>
          <div class="col-12 col-md-3 text-right">
            <b-button
              type="button"
              class="btn-primary"
              v-show="is_create && e_count == 0"
              @click="addEvent()"
              >✚ Create</b-button
            >
          </div>
        </div>
        <div class="row" v-if="is_create && e_count > 0">
          <div class="col-12 text-right">
            <b-button
              type="button"
              class="btn-primary"
              @click="editEvent(eventItem)"
              >Edit</b-button
            >
            <b-button type="button" class="btn-primary" style="margin-left: 8px" @click="resetEvent()"
              >Reset</b-button
            >
            <b-button
              type="button"
              class="btn-primary"
              style="margin-left: 8px"
              @click="startSpeakerRoom(eventItem)"
              >Start Speaker Room</b-button
            >
            <b-button
                type="button"
                class="btn-primary ml-2 white-border"
                style="margin-left: 8px"
                @click="addNewEvent()"
                >Add New Event</b-button
              >
          </div>

          <div class="col-12 col-md-8 offset-md-2 mb-3">
            <h4 class="text-yellow mb-1">Information</h4>
          </div>
          <div class="col-12 col-md-8 offset-md-4">
            <div class="text-left">
              <h5 class="text-yellow mt-3">
                Deceased name : {{ eventItem.decease_name }}
              </h5>
              <h5 class="text-yellow mt-3">
                Start Time : {{ eventItem.formatedDateTime }}
              </h5>
              <h5 class="text-yellow mt-3">
                Timezone : {{ eventItem.time_zone }}
              </h5>
              <h5 class="text-yellow mt-3" style="overflow-wrap: anywhere;">
                Donation URL : {{ eventItem.donation_url }}
              </h5>
              <h5 class="text-yellow mt-3 " style="overflow-wrap: anywhere;">
                Flowershop URL : {{ eventItem.flowershop_url }}
              </h5>
              <h5 class="text-yellow mt-3" style="overflow-wrap: anywhere;">
                Reminder URL : {{ eventItem.reminder_url }}
              </h5>
              <h5 class="text-yellow mt-3" style="overflow-wrap: anywhere;">
                Zoom meeting URL : {{ eventItem.zoom_meeting_url }}
              </h5>
              <h5 class="text-yellow mt-3" style="overflow-wrap: anywhere;">
                Video URL : {{ eventItem.video_url }}
              </h5>
              <h5 class="text-yellow mt-3" style="overflow-wrap: anywhere;">
                Visitor URL : {{ base_url }}/#/visitor/home/{{
                  generateRandom(eventItem.event_id)
                }}
              </h5>
              <h5 class="text-yellow mt-3" style="overflow-wrap: anywhere;">
                List Of Visitors : {{ base_url }}/#/visitor/admin/adminlivevisitors/{{
                  eventItem.event_id
                }}
              </h5>
              <h5 class="text-yellow mt-3" style="overflow-wrap: anywhere;">
                List Of Live Visitors : {{ base_url }}/#/visitor/livevisitors/{{
                  eventItem.event_id
                }}
              </h5>
              <!-- <h5 class="text-yellow mt-3">
                Condolence Telephone Number :
                <a :href="`tel:${eventItem.tel}`">{{ eventItem.tel }}</a>
              </h5> -->
              <h5 class="text-yellow mt-3">
                Record a condolence message : {{ eventItem.tel }}
              </h5>
            </div>
            <div class="photo-preview d-flex align-items-center w-100 mt-3">
              <h5 class="text-yellow mr-2">Program Image:</h5>
              <div class="flower" style="margin-bottom: 0.5rem" v-for="(input,k) in imagesArray" :key="k">
                <img
                  :src="input.program_url"
                  class="img-fluid"
                  alt="no img"
                  style="height: 100%"
                />
              </div>
              <!-- <div class="flower" style="margin-bottom: 0.5rem">
                <img
                  :src="eventItem.program_url"
                  class="img-fluid"
                  alt="no img"
                  style="height: 100%"
                />
              </div> -->
            </div>
            <!-- <div class="photo-preview d-flex align-items-center w-100 mt-3">
              <h5 class="text-yellow mr-2">Background Image:</h5>
              <div class="flower" style="margin-bottom: 0.5rem">
                <img
                  :src="eventItem.background_url"
                  class="img-fluid"
                  alt="no img"
                  style="height: 100%"
                />
              </div>
            </div> -->
            <div class="social mt-3 text-left">
              <textarea
                rows="6"
                v-model="social"
                style="width: 600px"
              ></textarea>
            </div>
            <div class="col-12 d-flex justify-content-between">
              <b-button
              type="button"
              class="btn-primary white-border text-right"
              style="margin-left: 0px"
              @click="previous()"
              >Previous</b-button>
               <b-button
              type="button"
              class="btn-primary white-border text-right"
              style="margin-right: 0px"
              @click="next()"
              >Next</b-button>
              </div>
          </div>
        </div>
        <div class="row" v-show="is_create && e_count == 0">
          <div class="col-12 col-md-8 offset-md-2 mt-3">
            <h4 class="text-yellow mb-1">There is no event yet.</h4>
          </div>
        </div>
        <div class="row" v-show="!is_create">
          <div class="col-12 col-md-8 offset-md-2">
            <h4 class="text-yellow mb-1">Create a new event</h4>
            <div class="custom-group mb-2">
              <label class="text-yellow mb-1">Deceased name</label>
              <input
                type="text"
                class="form-control form-input-control"
                v-model="eventItem.decease_name"
              />
            </div>
            <div class="custom-group mb-2">
              <label class="text-yellow mb-1">Start time</label>
              <date-picker
                v-model="eventItem.start_date_time"
                type="datetime"
                format="MM/DD/YYYY HH:mm"
                valueType="YYYY-MM-DD HH:mm:00"
              ></date-picker>
            </div>
            <div class="custom-group mb-2">
              <label class="text-yellow mb-1">Timezone</label>
              <b-form-select
                v-model="eventItem.time_zone"
                :options="timezones"
                class="form-input-control"
              ></b-form-select>
            </div>
            <div class="custom-group mb-2">
              <label class="text-yellow mb-1">Donation URL</label>
              <input
                type="text"
                class="form-control form-input-control"
                v-model="eventItem.donation_url"
              />
            </div>
            <div class="custom-group mb-2">
              <label class="text-yellow mb-1">Flower shop URL</label>
              <input
                type="text"
                class="form-control form-input-control"
                v-model="eventItem.flowershop_url"
              />
            </div>
            <div class="custom-group mb-2">
              <label class="text-yellow mb-1">Reminder URL</label>
              <input
                type="text"
                class="form-control form-input-control"
                v-model="eventItem.reminder_url"
              />
            </div>
            <div class="custom-group mb-2">
              <label class="text-yellow mb-1">Zoom meeting URL</label>
              <input
                type="text"
                class="form-control form-input-control"
                v-model="eventItem.zoom_meeting_url"
              />
            </div>
            <div class="custom-group mb-2">
              <label class="text-yellow mb-1">Video URL</label>
              <input
                type="text"
                class="form-control form-input-control"
                v-model="eventItem.video_url"
              />
            </div>
            <div class="custom-group mb-2">
              <label class="text-yellow mb-1"
                >Record a condolence message</label
              >
              <input
                type="text"
                class="form-control form-input-control"
                v-model="eventItem.tel"
              />
            </div>
            <div class="custom-group mb-2">
             <div v-for="(input,k) in inputs" :key="k">
               <!-- {{k}} -->
               <!-- {{input}} -->
              <div class="photo-preview d-flex align-items-center">
                  <!-- <div class="upload-photo mr-3" v-if="!eventItem.program_url">
                  Program Image in here
                </div> -->
                <div class="upload-photo mr-3" v-if="!inputs[k].program_url">
                  Program Image in here
                </div>
                <!-- <div
                  class="flower mr-3"
                  v-if="eventItem.program_url"
                  style="margin-bottom: 0.5rem"
                >
                  <img
                    :src="eventItem.program_url"
                    class="img-fluid round-image"
                  />
                </div> -->
                <div
                  class="flower mr-3"
                  v-if="inputs[k].program_url"
                  style="margin-bottom: 0.5rem"
                >
                  <img
                    :src="inputs[k].program_url"
                    class="img-fluid round-image"
                  />
                </div>
                
                <div class="photo-action text-left">
                 
                  <input
                    type="file"
                    ref="programImage"
                    @change="inputFile(k,$event)"
                    accept="image/x-png,image/gif,image/jpeg"
                    style="display: none"
                  />

                 
                  <button
                    type="button"
                    class="btn btn-primary"
                    :disabled="inputs[k].program_url!=''"
                    @click="$refs.programImage[k].click()"
                  >
                    Upload program image
                  </button>

                  <!-- <button
                    style="margin-left:10px"
                    type="button"
                    class="btn btn-primary"
                    @click="removeProgramImage(k)"
                  >
                    clear
                  </button> -->

                  <button
                    style="margin-left:10px"
                    type="button"
                    class="btn btn-primary"
                    @click="addUploadImageField(k)"
                    v-show="k == inputs.length-1"
                    >
                    <img
                      style="height:25px"
                      :src="add"
                      />
                    </button>

                    
                  <button
                    style="margin-left:10px"
                    type="button"
                    class="btn btn-primary"
                    @click="removeUploadImageField(k)"
                    v-show="k || ( !k && inputs.length > 1)"
                    >
                    <img
                      style="height:25px"
                      :src="remove"
                      />
                    </button>

                </div>
                </div>
                
              </div>
            </div>
            <!-- <div class="custom-group mb-2">
              <div class="photo-preview d-flex align-items-center">
                <div class="upload-photo mr-3" v-if="!eventItem.background_url">
                  Back Image in here
                </div>
                <div
                  class="flower mr-3"
                  v-if="eventItem.background_url"
                  style="margin-bottom: 0.5rem"
                >
                  <img
                    :src="eventItem.background_url"
                    class="img-fluid round-image"
                  />
                </div>
                <div class="photo-action text-left">
                  <input
                    type="file"
                    ref="backgroundImage"
                    @change="backgroundInputFile"
                    accept="image/x-png,image/gif,image/jpeg"
                    style="display: none"
                  />
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="$refs.backgroundImage.click()"
                  >
                    Upload background image
                  </button>
                  <button
                  style="margin-left:10px"
                    type="button"
                    class="btn btn-primary"
                    @click="removeBackgroundImage()"
                  >
                    clear
                  </button>
                </div>
              </div>
            </div> -->
          </div>
          <div class="text-right col-12 col-md-8 offset-md-2">
            <b-button type="button" class="btn-primary mr-3" v-if="isNew==false" @click="save()"
              >Save</b-button
            >
            <b-button type="button" class="btn-primary mr-3" v-if="isNew==true" @click="create()"
              >Create</b-button
            >
            <b-button type="button" class="btn-primary" @click="back()"
              >Back</b-button
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { getApiManager } from "../../api";
import { apiBaseUrl, liveBaseUrl } from "../../constants/config";
import DatePicker from "vue2-datepicker";
import {DoubleBounce} from 'vue-loading-spinner'
import "vue2-datepicker/index.css";
import { compressAccurately } from 'image-conversion';
// const moment = require("moment");
const moment = require('moment-timezone')
import add from "../../../src/assets/img/add.png"
import remove from "../../../src/assets/img/remove.png"
export default {
  data() {
    return {
      is_create: true,
      showLoader : false,
      dateForUpdate:null,
      e_count: 0,
      isNew:false,
      currentEvent:0,
      eventItem: {
        event_id: 0,
        decease_name: "",
        start_date_time: null,
        formatedDateTime:null,
        time_zone: "",
        program_url: "",
        // background_url:null,
        image: null,
        // backgroundImage:null,
        donation_url: "",
        flowershop_url: "",
        reminder_url: "",
        zoom_meeting_url: "",
        tel: "",
      },
      timezones: [
        { value: "PST", text: "PST" },
        { value: "EST", text: "EST" },
        { value: "MNT", text: "MNT" },
      ],
      base_url: window.location.origin,
      social: "",
      add:add,
      remove:remove,
      inputs: [{
        program_url: '',
        programImage: '',
      }],
      program_images:[],
      imagesArray:[]
    };
  },
  //getuploadImagebyEventId
  // event/create
  // event/modify
  // event/getuploadImage
  // event/uploadImage
  // deleteUploadImage
  
  mounted() {
    this.connectSocket();
    getApiManager()
      .post(`${apiBaseUrl}/api/admin/event/get`)
      .then((response) => {
        let res = response.data;
        if (res.status === true) {
          this.eventItem1 = res.detail;
          this.eventItem = res.detail[0];
          this.showLoader = true
          getApiManager()
          .post(`${apiBaseUrl}/api/admin/event/getuploadImagebyEventId`, {
            event_id:  this.eventItem.event_id,
          })
          .then((response) => {
            this.showLoader = false
              this.imagesArray=response.data.detail;
              if(this.imagesArray && this.imagesArray.length>0){
              for(var i=0;i<this.imagesArray.length;i++){
                this.imagesArray[i].program_url=`${apiBaseUrl}` + "/" + this.imagesArray[i].program_url
              }
            }
          })
          this.dateForUpdate = this.eventItem.start_date_time
          this.eventItem.start_date_time = this.getFormatedDate(this.eventItem.start_date_time);
          this.eventItem.formatedDateTime = this.getFormatedDate1(this.dateForUpdate);
          this.event_id = localStorage.setItem("event_id", res.detail.event_id);
          this.eventItem.program_url =
            `${apiBaseUrl}` + "/" + this.eventItem.program_url;
            // if(this.eventItem.background_url!=null){
            // this.eventItem.background_url =
            // `${apiBaseUrl}` + "/" + this.eventItem.background_url;
            // }else{
            //    this.eventItem.background_url =null
            // }
          this.social =
            this.eventItem.decease_name +
            " - Online funeral service - " +
            this.eventItem.start_date_time +
            " " +
            this.eventItem.time_zone +
            "\n\n" +
            "If you would like to purchase flowers, add your condolences, or donate to the family, please register yourself for the service several days in advance, you will see the options there. Have a look at all the pre-service activities once you’re registered.  All pre-service activities can be done days before the online service begins.\n" +
            "\n" +
            "Thank you\n\n" +
            this.base_url +
            "/#/visitor/home/" +
            this.eventItem.event_id;
          this.e_count = 1;
          this.is_create = true;
        } else {
          if (res.error == null) {
            this.$notify({
              group: "foo",
              type: "error",
              title: "Error",
              text: "Server Error",
              animation_type: "slide",
            });
          }
        }
      })
      .catch((error) => {});
  },
  methods: {
   
    connectSocket() {
      var ref = this;

      this.connection = new WebSocket(liveBaseUrl);
      this.connection.onmessage = function (event) {
        //this.isLoading = false;
        let data = JSON.parse(event.data);
        if (data.type == "start_service") {
          this.$router.push("/admin/whereby/"+this.eventItem.event_id);
        }
      };

      this.connection.onclose = (e) => {
        console.log(
          "Socket is closed. Reconnect will be attempted in 1 second."
        );
        setTimeout(() => {
          ref.connectSocket();
        }, 1000);
      };
    },
    getFormatedDate(newDate) {
      const formateddate=new Date(newDate)
      const firstdayMonth = formateddate.getMonth() + 1;
      const firstdayDay = formateddate.getDate();
      const firstdayYear = formateddate.getFullYear();
      const time = new Date(newDate).toLocaleTimeString('en', {  hour12: false, timeZone: 'UTC' });
      return ("0000" + firstdayYear.toString()).slice(-4) + "-" + ("00" + firstdayMonth.toString()).slice(-2) + "-" + ("00" + firstdayDay.toString()).slice(-2)+" "+time
      
    },
    getFormatedDate1(newDate){
      const formateddate=new Date(this.dateForUpdate)
      const firstdayMonth = formateddate.getMonth() + 1;
      const firstdayDay = formateddate.getDate();
      const firstdayYear = formateddate.getFullYear();
      const time = new Date(newDate).toLocaleTimeString('en', {  hour12: true, timeZone: 'UTC' });
      return ("0000" + firstdayYear.toString()).slice(-4) + "-" + ("00" + firstdayMonth.toString()).slice(-2) + "-" + ("00" + firstdayDay.toString()).slice(-2)+" "+time
    },
//     removeBackgroundImage(){
// this.eventItem.background_url=null;
//     },
    removeProgramImage(k){
       console.log('k',k);
      console.log("8888")
      this.eventItem.program_url=null;
      this.inputs[k].program_url=''
    },
    next () {
      if(!(this.currentEvent >= this.eventItem1.length-1)){
        this.currentEvent++;
        this.eventItem=this.eventItem1[this.currentEvent]
        this.dateForUpdate = this.eventItem.start_date_time
        this.eventItem.start_date_time = this.getFormatedDate(this.eventItem.start_date_time);
        this.eventItem.formatedDateTime = this.getFormatedDate1(this.dateForUpdate);
        // this.eventItem.background_url =`${apiBaseUrl}` + "/" + this.eventItem.background_url;
        this.social =
        this.eventItem.decease_name +
            " - Online funeral service - " +
            this.eventItem.start_date_time +
            " " +
            this.eventItem.time_zone +
            "\n\n" +
            "If you would like to purchase flowers, add your condolences, or donate to the family, please register yourself for the service several days in advance, you will see the options there. Have a look at all the pre-service activities once you’re registered.  All pre-service activities can be done days before the online service begins.\n" +
            "\n" +
            "Thank you\n\n" +
            this.base_url +
            "/#/visitor/home/" +
            this.eventItem.event_id;
            this.showLoader = true
        getApiManager()
          .post(`${apiBaseUrl}/api/admin/event/getuploadImagebyEventId`, {
            event_id:  this.eventItem.event_id,
          })
          .then((response) => {
            this.showLoader = false
              this.imagesArray=response.data.detail;
              if(this.imagesArray && this.imagesArray.length>0){
              for(var i=0;i<this.imagesArray.length;i++){
                this.imagesArray[i].program_url=`${apiBaseUrl}` + "/" + this.imagesArray[i].program_url
              }
            }
          })
      }
    },
    
    // navigate to previous fruit
    previous () {
      if(this.currentEvent > 0){
        this.currentEvent--;
        this.eventItem=this.eventItem1[this.currentEvent]
        this.social =
        this.eventItem.decease_name +
            " - Online funeral service - " +
            this.eventItem.start_date_time +
            " " +
            this.eventItem.time_zone +
            "\n\n" +
            "If you would like to purchase flowers, add your condolences, or donate to the family, please register yourself for the service several days in advance, you will see the options there. Have a look at all the pre-service activities once you’re registered.  All pre-service activities can be done days before the online service begins.\n" +
            "\n" +
            "Thank you\n\n" +
            this.base_url +
            "/#/visitor/home/" +
            this.eventItem.event_id;
            this.showLoader = true
        getApiManager()
          .post(`${apiBaseUrl}/api/admin/event/getuploadImagebyEventId`, {
            event_id:  this.eventItem.event_id,
          })
          .then((response) => {
            this.showLoader = false
              this.imagesArray=response.data.detail;
              if(this.imagesArray && this.imagesArray.length>0){
              for(var i=0;i<this.imagesArray.length;i++){
                this.imagesArray[i].program_url=`${apiBaseUrl}` + "/" + this.imagesArray[i].program_url
              }
            }
          })
      }
    },
    // generateUrl() {
    //   getApiManager()
    //     .post(`${apiBaseUrl}/api/vonage/generateUrl`, {
    //       decease_name: this.eventItem.decease_name,
    //     })
    //     .then((response) => {
    //       let res = response.data;
    //     })
    //     .catch((error) => {});
    // },
    generateNumber(min, max) {
      let r = Math.random() * (max - min) + min;
      return Math.floor(r);
    },
    generateRandom(number) {
      return window.btoa(number);
    },
    onAction(action, data = null) {
      alert(action);
      console.log(data);
    },
    addEvent() {
      this.is_create = false;
    },
    editEvent() {
      const formateddate=moment(this.eventItem.start_date_time, 'YYYY-MM-DD HH:mm').toDate()
      console.log("777",formateddate)
      this.showLoader = true
      getApiManager()
          .post(`${apiBaseUrl}/api/admin/event/getuploadImagebyEventId`, {
            event_id:  this.eventItem.event_id,
          })
          .then((response) => {
            this.showLoader = false
              this.imagesArray=response.data.detail;
              if(this.imagesArray && this.imagesArray.length>0){
              for(var i=0;i<this.imagesArray.length;i++){
                this.imagesArray[i].program_url=`${apiBaseUrl}` + "/" + this.imagesArray[i].program_url
              }
            
              this.inputs=this.imagesArray
            }
          })
      const firstdayMonth = formateddate.getMonth() + 1;
      const firstdayDay = formateddate.getDate();
      const firstdayYear = formateddate.getFullYear();
      const time = moment(this.eventItem.start_date_time, 'YYYY-MM-DD HH:mm').toDate().toLocaleTimeString('en', { hour12: false });
      this.eventItem.start_date_time=("0000" + firstdayYear.toString()).slice(-4) + "-" + ("00" + firstdayMonth.toString()).slice(-2) + "-" + ("00" + firstdayDay.toString()).slice(-2)+" "+time
      this.is_create = false;
    },
    addNewEvent(){
      this.eventItem.event_id=''
      this.isNew=true;
      // window.open('http://localhost:8080/#/visitor/home/MTE=', "_blank");
      this.is_create = false;
      this.eventItem.decease_name="";
      this.eventItem.start_date_time="";
      this.eventItem.time_zone="";
      this.eventItem.donation_url="";
      this.eventItem.flowershop_url="";
      this.eventItem.reminder_url="";
      this.eventItem.zoom_meeting_url="";
      this.eventItem.tel="";
      this.eventItem.program_url="";
      this.eventItem.background_url=""
    },
    startSpeakerRoom(event) {
      this.showLoader = true;
      getApiManager()
      .post(`${apiBaseUrl}/api/admin/event/getEventByID`,{
          event_id:event.event_id
        })
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            this.eventItem = res.detail[0];
            this.eventItem.start_date_time = this.getFormatedDate(this.eventItem.start_date_time);
            this.eventItem.formatedDateTime = this.getFormatedDate1(this.eventItem.start_date_time);
            this.eventItem.program_url =`${apiBaseUrl}` + "/" + this.eventItem.program_url;
            // this.eventItem.background_url =`${apiBaseUrl}` + "/" + this.eventItem.background_url;
            if (this.eventItem.status == 3) {
              this.$notify({
                group: "foo",
                type: "error",
                title: "Finished",
                text: "The event was already finished.",
                animation_type: "slide",
              });
              
              this.showLoader = false;
            } else {
              this.speakers = [];
              // getApiManager()
              //   .post(`${apiBaseUrl}/api/vonage/generateUrl`, {
              //     decease_name: this.eventItem.decease_name,
              //   })
              //   .then((response) => {
              //     let res = response.data;
                  getApiManager()
                    .post(
                      `${apiBaseUrl}/api/admin/speaker/sendEmailToSpeakers`,
                      {
                        decease_name: this.eventItem.decease_name,
                        event_id:this.eventItem.event_id
                      }
                    )
                    .then((response) => {
                      let res = response.data;
                      if (res.status === true) {
                        this.connection.send(
                          JSON.stringify({
                            type: "start_service",
                            event_id: this.eventItem.event_id,
                          })
                        );
                        localStorage.setItem('event_id',this.eventItem.event_id)
                        this.$router.push("/admin/whereby/"+this.eventItem.event_id);
                      }
                      this.showLoader = false;
                    })
                    .catch((error) => {
                      console.log(error);
                      this.showLoader = false;
                    });
                // })
                // .catch((error) => {
                //   this.showLoader = false;
                // });
            }
          }
        });
    },
    resetEvent() {
      this.showLoader=true;
      getApiManager()
        .post(`${apiBaseUrl}/api/vonage/RemoveSession`, {
          decease_name: this.eventItem.decease_name,
        })
        .then((result) => {
          console.log("44444444444444", result);
          if (result.data.success == true) {
            getApiManager()
              .post(`${apiBaseUrl}/api/admin/event/reset`, {
                event_id: this.eventItem.event_id,
              })
              .then((response) => {
                let res = response.data;
                if (res.status === true) {
                  this.$notify({
                    group: "foo",
                    type: "success",
                    title: "Success",
                    text: "The event was reset successfully.",
                    animation_type: "slide",
                  });
                  
                  this.showLoader=false;
                  // window.location.reload();
                } else {
                  this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Server Error",
                    animation_type: "slide",
                  });
                  this.showLoader=false;
                }
              })
              .catch((error) => {
                this.showLoader=false;
              });
          }else{
            this.showLoader=false;
          }
        });
    },
    save() {
      const formData = new FormData();
      console.log("]]]]",this.eventItem)
      for (let key in this.eventItem) {
        formData.append(key, this.eventItem[key]);
      }
      let access_url = `${apiBaseUrl}/api/admin/event/create`;
      if (this.eventItem.event_id != 0) {
        access_url = `${apiBaseUrl}/api/admin/event/modify`;
      }
      getApiManager()
        .post(access_url, formData)
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            this.$notify({
              group: "foo",
              type: "success",
              title: "Success",
              text: "The event was created successfully.",
              animation_type: "slide",
            });
            window.location.reload();
          } else if (res.status == 413) {
            this.$notify({
              group: "foo",
              type: "warn",
              title: "Warning",
              text: "Please upload image less then 1 mb",
              animation_type: "slide",
            });
          } else {
            this.$notify({
              group: "foo",
              type: "error",
              title: "Error",
              text: "Server Error",
              animation_type: "slide",
            });
          }
        })
        .catch((error) => {
          this.$notify({
            group: "foo",
            type: "warn",
            title: "Warning",
            text: "Please upload image less then 1 mb",
            animation_type: "slide",
          });
          console.log(error);
        });
    },
    create() {
      const formData = new FormData();
      for (let key in this.eventItem) {
        formData.append(key, this.eventItem[key]);
      }
      let access_url = `${apiBaseUrl}/api/admin/event/create`;
      if(this.eventItem.event_id!='' && this.eventItem.event_id!=0){
        access_url = `${apiBaseUrl}/api/admin/event/modify`;
      }
     
      console.log("4444444444",formData)
      getApiManager()
        .post(access_url, formData)
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            this.$notify({
              group: "foo",
              type: "success",
              title: "Success",
              text: "The event was created successfully.",
              animation_type: "slide",
            });
             window.location.reload();
          } else if (res.status == 413) {
            this.$notify({
              group: "foo",
              type: "warn",
              title: "Warning",
              text: "Please upload image less then 1 mb",
              animation_type: "slide",
            });
          } else {
            this.$notify({
              group: "foo",
              type: "error",
              title: "Error",
              text: "Server Error",
              animation_type: "slide",
            });
          }
        })
        .catch((error) => {});
    },
    back() {
      this.is_create = true;
      window.location.reload();
    },
    async inputFile(k,event) {
      console.log("UUUUUUUUUUU", this.eventItem.program_url)
      if(this.eventItem.event_id==''){
        const compressedFile = await compressAccurately(this.eventItem.program_url, {
          size: 1000, // Target size in kilobytes (2MB)
          scale: 0.7, // Compression scale, 0.7 is a good starting point
        });
        const file =  new File([compressedFile], event.target.files[0].name, { type: compressedFile.type });
        this.eventItem.program_url = file
        const formData = new FormData();
      for (let key in this.eventItem) {
        formData.append(key, this.eventItem[key]);
      }
      let access_url = `${apiBaseUrl}/api/admin/event/create`;
      getApiManager()
        .post(access_url, formData)
        .then((resp) => {
          console.log(";;;;;",resp.data.event_id)
          this.eventItem.event_id=resp.data.event_id
          
          this.imageData=event.target.files[0].name;
      this.image=event.target.files[0];
      //set image to the div area
      var reader = new FileReader();
      var ref = this;
      reader.onload = function (e) {
        ref.eventItem.program_url = file;
      };
      reader.readAsDataURL(event.target.files[0]);
      const formData = new FormData();
    //  if(this.is_create == false){

    //  }
        formData.append('program_url',file);
        formData.append('event_id', resp.data.event_id);
        this.showLoader = true;
      getApiManager()
        .post(`${apiBaseUrl}/api/admin/event/uploadImage`,formData)
        .then((response) => {
        getApiManager()
          .post(`${apiBaseUrl}/api/admin/event/getuploadImage`, {
            program_image_id: response.data.data,
          })
          .then((response) => {
            console.log("pppppp",this.inputs[k])
              this.inputs[k].program_image_id=response.data.detail[0].program_image_id;
              this.inputs[k].program_url=`${apiBaseUrl}` + "/" + response.data.detail[0].program_url;
              this.showLoader = false;
              // this.inputs.push({
              //   program_image_id:response.data.detail[0].program_image_id,
              //   program_image_url:`${apiBaseUrl}` + "/" + response.data.detail[0].program_url,
              // })

          })
          console.log("2222222222",this.inputs)
          this.showLoader = false;
        })
        })
      }else{
        this.showLoader = true;

        const compressedFile = await compressAccurately(event.target.files[0], {
          size: 1000, // Target size in kilobytes (2MB)
          scale: 0.7, // Compression scale, 0.7 is a good starting point
        });

      const file =  new File([compressedFile], event.target.files[0].name, { type: compressedFile.type });
        // Now you have the compressed file, you can use it as needed
      this.imageData=event.target.files[0].name;
      this.image=event.target.files[0];
      //set image to the div area
      var reader = new FileReader();
      var ref = this;
      reader.onload = function (e) {
        ref.eventItem.program_url = file;
      };
      reader.readAsDataURL(event.target.files[0]);
      const formData = new FormData();
    //  if(this.is_create == false){

    //  }
        formData.append('program_url', file);
        formData.append('event_id', this.eventItem.event_id);
      getApiManager()
        .post(`${apiBaseUrl}/api/admin/event/uploadImage`,formData)
        .then((response) => {
        getApiManager()
          .post(`${apiBaseUrl}/api/admin/event/getuploadImage`, {
            program_image_id: response.data.data,
          })
          .then((response) => {
            console.log("pppppp",this.inputs[k])
              this.inputs[k].program_image_id=response.data.detail[0].program_image_id;
              this.inputs[k].program_url=`${apiBaseUrl}` + "/" + response.data.detail[0].program_url;
              this.showLoader = false;
              // this.inputs.push({
              //   program_image_id:response.data.detail[0].program_image_id,
              //   program_image_url:`${apiBaseUrl}` + "/" + response.data.detail[0].program_url,
              // })

          })
          console.log("2222222222",this.inputs)
          this.showLoader = false;
        })
      }
    },
   
    // backgroundInputFile(event) {
    //   this.eventItem.background_url = event.target.files[0].name;
    //   this.eventItem.backgroundImage = event.target.files[0];
    //   //set image to the div area
    //   var reader = new FileReader();
    //   var ref = this;
    //   reader.onload = function (e) {
    //     ref.eventItem.background_url = e.target.result;
    //   };
    //   reader.readAsDataURL(event.target.files[0]);
    // },

    addUploadImageField(k){
      console.log('k',k);
          this.inputs.push({
          program_url: '',
          programImage: '',
      })
      console.log(this.inputs)
    },
    removeUploadImageField (index) {
      console.log('index',index);
      console.log("88888888888",this.inputs[index].program_image_id)
      let program_imageId=this.inputs[index].program_image_id
      this.inputs.splice(index, 1)
      getApiManager()
          .post(`${apiBaseUrl}/api/admin/event/deleteUploadImage`, {
            program_image_id: program_imageId,
          })
          .then((response) => {
            console.log("&&&&&",response)
          })
    },
  },
  components: {
    DoubleBounce,
    "date-picker": DatePicker,
  },
  computed: {},
  watch: {},
};
</script>
<style scoped>
.loaderWrapper{
  position: absolute;
    height: 89vh;
    width: 100vw;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00000082;
    z-index: 999;
}
</style>